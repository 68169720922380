import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Spinner, Row, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import basicSolicitude from '../../../components/Solicitude/basicSolicitude';
import ItemsAndRoute from '../../../components/Client/ClientShow/ItemsAndRoute';
import QuestionAndAnswers from '../../../components/Client/ClientShow/QuestionAndAnswers';
import Quotations from '../../../components/Client/ClientShow/Quotations';
import {
  DefaultHeader,
  DefaultModal,
  ExpiredSolicitudeHeader
} from '../../../components';
import {
  deactivateSolicitudeRequest,
  showSolicitudeRequest
} from '../../../requests/solicitudes';
import { sendAlert } from '../../../actions/utils';
import { sendContactFormRequest } from '../../../requests/utils';
import QuotationCard from '../../../components/TableCells/QuotationCard';

const ClientSolicitudeShow = ({ match }) => {
  const [solicitude, setSolicitude] = useState(basicSolicitude);
  const [isFetching, setIsFetching] = useState(false);
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const { id } = match.params;
  const _isMounted = useRef(true);
  const modelUrl = '/client/solicitudes';
  const {
    hasQuotation,
    selectedQuotation,
    status,
    statusTranslated: translatedStatus,
    endDate,
    startDate,
    description,
    deliveryAddressAttributes,
    pickupAddressAttributes,
    itemsAttributes,
    pickupOption,
    deliveryOption,
    expired
  } = Boolean(solicitude) && solicitude;
  const { driver, paid } = Boolean(selectedQuotation) && selectedQuotation;
  const editOfferUrl = {
    pathname: `/client/solicitudes/${id}/edit`,
    state: { solicitudeId: id }
  };

  const fetchSolicitude = () => {
    setIsFetching(true);
    showSolicitudeRequest(id, {
      dispatch,
      successCallback: response => {
        if (_isMounted.current) {
          setSolicitude(camelCaseRecursive(response.data));
          setIsFetching(false);
        }
      }
    });
  };

  const handleSuccessDeactivateSolicitude = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Haz eliminado la solicitud con éxito'
      })
    );
    history.push(modelUrl);
  };

  const handleDeactivateSolicitude = () => {
    deactivateSolicitudeRequest(id, {
      dispatch,
      successCallback: handleSuccessDeactivateSolicitude
    });
    setModalShow(false);
  };

  const handleSuccessReport = () => {
    dispatch(
      sendAlert({ kind: 'success', message: 'Se ha enviado el reporte' })
    );
    history.push(modelUrl);
  };

  const handleReportRequest = () => {
    const sendParams = {
      email: user.email,
      message: `Me gustaria reportar el envio de la solicitud ${id}`
    };
    sendContactFormRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessReport
    });
    setModalShow(false);
  };

  const [modalFunction, setModalFunction] = useState(undefined);
  const [confirmationText, setConfirmationText] = useState({});
  const [modalTitle, setModalTitle] = useState('');

  useEffect(() => {
    if (!selectedQuotation) {
      setModalFunction(() => handleDeactivateSolicitude);
      setConfirmationText(
        <p className="text-center mt-4 mb-3">
          ¿Estás seguro de eliminar ésta solicitud de envío?
        </p>
      );
      setModalTitle('Eliminar solicitud de envío');
    } else {
      setModalFunction(() => handleReportRequest);
      setConfirmationText(
        <p className="text-center mt-4 mb-3">
          ¿Estás seguro de reportar éste Briiing?
        </p>
      );
      setModalTitle('Reportar Briiing');
    }
  }, [selectedQuotation]);

  useEffect(() => {
    fetchSolicitude();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const setBreadCrumb = () => {
    switch (location.state) {
      case 'fromDashboard':
        return [
          {
            key: 1,
            name: 'Inicio',
            href: `/client/home`
          },
          { key: 2, name: 'Detalle solicitud' }
        ];
      case 'fromSolicitudesIndex':
        return [
          {
            key: 1,
            name: 'Mis Solicitudes',
            href: `/client/solicitudes`
          },
          { key: 2, name: 'Detalle solicitud' }
        ];
      case 'fromShipmentsDoneIndex':
        return [
          {
            key: 1,
            name: 'Envíos Realizados',
            href: `/client/shipments`
          },
          { key: 2, name: 'Detalle solicitud' }
        ];
      default:
        return [
          {
            key: 1,
            name: 'Inicio',
            href: `/client/home`
          },
          { key: 2, name: 'Detalle solicitud' }
        ];
    }
  };

  return (
    <>
      {isFetching ? (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          <DefaultHeader breadcrumb={setBreadCrumb()} />
          {expired && (
            <ExpiredSolicitudeHeader
              editOfferUrl={editOfferUrl}
              setModalShow={setModalShow}
            />
          )}
          <div
            className={`client-solicitude-show ${
              expired ? 'expired opacity-animation' : ''
            }`}
          >
            <ItemsAndRoute
              quotationPaid={paid}
              hasQuotation={hasQuotation}
              itemsAttributes={itemsAttributes}
              description={description}
              pickupStartDate={startDate}
              deliveryEndDate={endDate}
              deliveryAddressAttributes={deliveryAddressAttributes}
              pickupAddressAttributes={pickupAddressAttributes}
              totalValue={selectedQuotation?.cost}
              status={status}
              translatedStatus={translatedStatus}
              solicitudeId={id}
              solicitude={solicitude}
              pickupOption={pickupOption}
              deliveryOption={deliveryOption}
              expired={expired}
            />
            {!expired && hasQuotation && <hr className="my-4" />}
            {!expired && hasQuotation && selectedQuotation && (
              <>
                <QuotationCard
                  split
                  fromSolicitude
                  driver={driver}
                  quotation={selectedQuotation}
                  withButton={false}
                  withCurrency={false}
                />
              </>
            )}
            {!expired && !selectedQuotation && (
              <>
                <h6>Ofertas</h6>
                <Quotations
                  solicitudeId={id}
                  pickupStartDate={startDate}
                  deliveryEndDate={endDate}
                  modelUrl={modelUrl}
                  expired={expired}
                />
              </>
            )}
            {!expired && (
              <>
                <hr className="my-4" />
                <QuestionAndAnswers solicitudeId={id} />
              </>
            )}
            <Row className="my-4 d-flex justify-content-end">
              {!expired && (
                <Button
                  variant={selectedQuotation ? 'outline-secondary' : 'danger'}
                  onClick={() => setModalShow(true)}
                  className="px-4"
                >
                  {modalTitle}
                </Button>
              )}
              <DefaultModal
                title={modalTitle}
                body={confirmationText}
                show={modalShow}
                handleClose={() => setModalShow(false)}
                handleConfirm={modalFunction}
                titleBtnClose="Cancelar"
                titleBtnSave="Confirmar"
              />
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default ClientSolicitudeShow;
