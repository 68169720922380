import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ClientItemDescription from './ClientItemDescription';
import RouteDescription from './RouteDescription';

const ItemsAndRoute = ({
  roleProfile,
  itemsAttributes,
  description,
  pickupStartDate,
  deliveryEndDate,
  deliveryAddressAttributes,
  pickupAddressAttributes,
  totalValue,
  isQuotation,
  hasQuotation,
  quotationPaid,
  status,
  hasMyOffer,
  amIBestQuotation,
  parsedLowestOfferAmount,
  parsedHighestOfferAmount,
  quotationCount,
  locationUrl,
  translatedStatus,
  solicitudeId,
  pickupOption,
  deliveryOption,
  solicitude,
  tripId,
  expired
}) => {
  const isFragile = itemsAttributes[0]?.isFragile;

  return (
    <>
      <Row className="">
        <Col
          md={12}
          lg={5}
          xl={6}
          className="d-flex flex-column items-container left-animation"
        >
          {itemsAttributes?.map((item, index) => (
            <ClientItemDescription
              key={item.name}
              itemIndex={index}
              images={item.itemImages}
              title={item.name}
              height={item.height}
              length={item.length}
              width={item.width}
              weight={item.weight}
              comments={description}
              isFragile={isFragile}
              tripId={tripId}
            />
          ))}
        </Col>
        <Col md={12} lg={7} xl={6} className="items-route right-animation">
          <RouteDescription
            expired={expired}
            hasQuotation={hasQuotation}
            roleProfile={roleProfile}
            pickupStartDate={pickupStartDate}
            deliveryEndDate={deliveryEndDate}
            deliveryAddressAttributes={deliveryAddressAttributes}
            pickupAddressAttributes={pickupAddressAttributes}
            totalValue={totalValue}
            solicitudeId={solicitudeId}
            isQuotation={isQuotation}
            quotationPaid={quotationPaid}
            status={status}
            translatedStatus={translatedStatus}
            hasMyOffer={hasMyOffer}
            solicitude={solicitude}
            amIBestQuotation={amIBestQuotation}
            parsedLowestOfferAmount={parsedLowestOfferAmount}
            parsedHighestOfferAmount={parsedHighestOfferAmount}
            quotationCount={quotationCount}
            locationUrl={locationUrl}
            pickupOption={pickupOption}
            deliveryOption={deliveryOption}
          />
        </Col>
      </Row>
    </>
  );
};

export default ItemsAndRoute;
