import React from 'react';
import { Field, getIn } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { FormikInput, FormikCheckBox, NestedAttributes, InputImage } from '..';

const ItemsAttributes = ({
  itemsAttributes,
  errors,
  touched,
  setFieldValue,
  deleteItemsPhotos
}) => {
  const addItem = () => {
    const mapResults = itemsAttributes.map((body, index) => {
      if (body._destroy) return undefined;

      return (
        <div
          key={`solicitude-item-${index.toString()}`}
          className="container-fields-client-items opacity-animation"
        >
          {index > 0 && <hr className="my-5" />}
          <h6>Objeto a enviar N˚{index + 1}</h6>
          <Row className="pb-2">
            <Col md={4}>
              <Field name={`solicitude[itemsAttributes][${index}][name]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Nombre"
                    placeholder="Qué envías"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name={`solicitude[itemsAttributes][${index}][net_value]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="number"
                    label="Valor"
                    placeholder="Ingresa el valor de tu objeto en pesos"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name={`solicitude[itemsAttributes][${index}][weight]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="number"
                    label="Peso (kg)"
                    placeholder="0.0 kg"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <h6>Medidas</h6>
          <Row>
            <Col md={12}>
              <Field name={`solicitude[itemsAttributes][${index}][no_medidas]`}>
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    abbr="true"
                    field={field}
                    className="mt-3"
                    label="No quiero ingresar medidas"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          {!body.no_medidas && (
            <Row>
              <Col md={4}>
                <Field name={`solicitude[itemsAttributes][${index}][height]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="number"
                      label="Altura (cm)"
                      placeholder="0 cm"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4}>
                <Field name={`solicitude[itemsAttributes][${index}][width]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="number"
                      label="Ancho (cm)"
                      placeholder="0 cm"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4}>
                <Field name={`solicitude[itemsAttributes][${index}][length]`}>
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="number"
                      label="Largo (cm)"
                      placeholder="0 cm"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={4} xl={4}>
              <Field name={`solicitude[itemsAttributes][${index}][is_fragile]`}>
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    abbr="true"
                    field={field}
                    className="mt-3"
                    label="Producto Frágil"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col
              md={8}
              xl={8}
              className="d-flex align-items-xl-left flex-column"
            >
              <h6 className="mt-3 mb-2 mr-xl-3">Agregar imágenes del objeto</h6>
              <Field
                name={`solicitude[itemsAttributes][${index}][item_images]`}
              >
                {({ field, meta }) => (
                  <InputImage
                    images={meta.value}
                    imageKey="file_url_minified"
                    destroyImage={imageId => {
                      setFieldValue(
                        'solicitude[deleteItemsPhotos]',
                        [...deleteItemsPhotos, imageId].flat()
                      );
                    }}
                    getImages={images => {
                      const nameImages = `solicitude[itemsAttributes][${index}][images]`;
                      setFieldValue(
                        nameImages,
                        images.map(i => i.file).filter(i => i)
                      );
                      setFieldValue(
                        field.name,
                        images.map(img => ({
                          file_url_minified: img.file_url_minified,
                          filename: img?.filename || img.file?.name,
                          file: img.file,
                          id: img?.id
                        }))
                      );
                    }}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </div>
      );
    });

    return (
      <>
        <NestedAttributes
          mapInputs={mapResults}
          arrayValues={itemsAttributes}
          setFieldValue={setFieldValue}
          valuePath="solicitude[itemsAttributes]"
          newAttributes={{
            name: '',
            net_value: '',
            height: '',
            width: '',
            length: '',
            weight: '',
            is_fragile: false,
            images: [],
            item_images: []
          }}
          addTitle="Añadir otro objeto"
          classNameDeleteBtn="mt-20"
          deleteBtnTooltipText="Eliminar objeto"
        />
      </>
    );
  };

  return addItem();
};

export default ItemsAttributes;
