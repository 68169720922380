/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, Form, withFormik, getIn } from 'formik';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { licenses } from '../../Driver/Profile/formOptions';
import { FormikSelect, UploadImage, UploadFile } from '../../../components';
import { InputImage } from '../../../components/Utils/Upload';

const LicenceForm = ({
  errors,
  touched,
  modelName,
  onHide,
  isValid,
  setFieldValue,
  setFieldTouched,
  values,
  myState,
  setStep
}) => {
  const {
    frontLicense,
    backLicense,
    backgroundCertificate,
    deleteVehiclesPhotos,
    frontIdentity,
    backIdentity
  } = myState.user?.driverAttributes;
  const { avatar } = myState?.user;
  const [show, setShow] = useState(false);
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="opacity-animation steps_register">
      <h5 className="text-center mb-2">Tus datos de transporte</h5>
      <Form className="edit-driver-profile">
        <Row>
          <Col md={12}>
            <Field name={`${modelName}[licenseType]`}>
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de Licencia"
                  placeholder="Seleccionar licencia"
                  options={licenses}
                  defaultValue="class_b"
                  setFieldValue={setFieldValue}
                  onChange={data =>
                    setFieldValue(field.name, data ? data.value : '')
                  }
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} className="mt-3 mt-md-0">
            <Field name={`${modelName}[backgroundCertificate]`}>
              {({ field }) => {
                return (
                  <UploadFile
                    {...field}
                    abbr
                    numberId="02"
                    previewFile={backgroundCertificate}
                    label="Certificado Antecedentes"
                    accept="application/pdf"
                    setFieldValue={setFieldValue}
                    fileName={getIn(backgroundCertificate, 'filename')}
                    onChange={images => setFieldValue(field.name, images)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isForStepRegister
                  />
                );
              }}
            </Field>
            <label>
              <small>
                Para obtener este documento{' '}
                <a
                  style={{ fontWeight: 'bold' }}
                  href="https://www.chileatiende.gob.cl/fichas/3442-certificado-de-antecedentes-e-informes-de-inhabilidades"
                >
                  haz click aqui
                </a>
                .
              </small>
            </label>
          </Col>
          <Col md={6} className="mb-4">
            <Field name="user[avatar]">
              {({ field }) => {
                return (
                  <UploadImage
                    {...field}
                    accept="image/png, image/jpg, image/jpeg"
                    numberId="01"
                    previewFile={avatar}
                    imageUrl={getIn(avatar, 'fileUrlMinified')}
                    label="Foto Personal"
                    classNameImg="img-avatar-input-steps-register"
                    classNameEmpty="empty-img-avatar-input-steps-register"
                    setFieldValue={setFieldValue}
                    onChange={images => setFieldValue(field.name, images)}
                    isForStepRegister
                  />
                );
              }}
            </Field>
          </Col>
          <Col md={6} className="mb-4">
            <Field name={`${modelName}[frontLicense]`}>
              {({ field }) => {
                return (
                  <UploadImage
                    {...field}
                    abbr
                    previewFile={frontLicense}
                    numberId="02"
                    label="Licencia frontal"
                    classNameImg="img-license-input-steps-register"
                    classNameEmpty="empty-img-input-steps-register"
                    accept="image/jpg, image/png, image/jpeg"
                    setFieldValue={setFieldValue}
                    imageUrl={getIn(frontLicense, 'fileUrlMinified')}
                    fileName={getIn(frontLicense, 'filename')}
                    onChange={images => setFieldValue(field.name, images)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isForStepRegister
                  />
                );
              }}
            </Field>
          </Col>
          <Col md={6} className="mb-4">
            <Field name={`${modelName}[backLicense]`}>
              {({ field }) => {
                return (
                  <UploadImage
                    {...field}
                    abbr
                    previewFile={backLicense}
                    numberId="03"
                    label="Licencia trasera"
                    classNameImg="img-license-input-steps-register"
                    classNameEmpty="empty-img-input-steps-register"
                    accept="image/jpg, image/png, image/jpeg"
                    setFieldValue={setFieldValue}
                    imageUrl={getIn(backLicense, 'fileUrlMinified')}
                    fileName={getIn(backLicense, 'filename')}
                    onChange={images => setFieldValue(field.name, images)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isForStepRegister
                  />
                );
              }}
            </Field>
          </Col>
          <Col md={6} className="mb-4">
            <Field name={`${modelName}[frontIdentity]`}>
              {({ field }) => {
                return (
                  <UploadImage
                    {...field}
                    abbr
                    previewFile={frontIdentity}
                    numberId="04"
                    label="Cédula de identidad (frontal)"
                    classNameImg="img-license-input-steps-register"
                    classNameEmpty="empty-img-input-steps-register"
                    accept="image/jpg, image/png, image/jpeg"
                    setFieldValue={setFieldValue}
                    imageUrl={getIn(frontIdentity, 'fileUrlMinified')}
                    fileName={getIn(frontIdentity, 'filename')}
                    onChange={images => setFieldValue(field.name, images)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isForStepRegister
                  />
                );
              }}
            </Field>
          </Col>
          <Col md={6} className="mb-4">
            <Field name={`${modelName}[backIdentity]`}>
              {({ field }) => {
                return (
                  <UploadImage
                    {...field}
                    abbr
                    previewFile={backIdentity}
                    numberId="05"
                    label="Cédula de identidad (reverso)"
                    classNameImg="img-license-input-steps-register"
                    classNameEmpty="empty-img-input-steps-register"
                    accept="image/jpg, image/png, image/jpeg"
                    setFieldValue={setFieldValue}
                    imageUrl={getIn(backIdentity, 'fileUrlMinified')}
                    fileName={getIn(backIdentity, 'filename')}
                    onChange={images => setFieldValue(field.name, images)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    isForStepRegister
                  />
                );
              }}
            </Field>
          </Col>
        </Row>

        {values?.user?.driverAttributes?.vehiclesAttributes?.map(
          (vehicle, index) => (
            <Row className="d-flex justify-content-end">
              <Col md={12}>
                <h6 className="my-5 mb-4">Agrega imágenes de tu vehículo</h6>
                <Field
                  name={`${modelName}[vehiclesAttributes][${index}][vehicle_photos]`}
                >
                  {({ field, meta }) => (
                    <InputImage
                      images={meta.value}
                      imageKey="file_url_minified"
                      destroyImage={imageId => {
                        setFieldValue(
                          `${modelName}[deleteVehiclesPhotos]`,
                          [...deleteVehiclesPhotos, imageId].flat()
                        );
                      }}
                      getImages={images => {
                        const nameImages = `${modelName}[vehiclesAttributes][${index}][photos]`;
                        setFieldValue(
                          nameImages,
                          images.map(i => i.file).filter(i => i)
                        );
                        setFieldValue(
                          field.name,
                          images.map(img => ({
                            file: img.file,
                            file_url_minified: img.file_url_minified,
                            filename: img?.filename || img.file?.name,
                            id: img?.id
                          }))
                        );
                      }}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          )
        )}

        <Button
          variant="primary"
          size="lg"
          className="btn mb-2 login-btn driver-btn"
          block
          type="submit"
          disabled={!isValid}
          onClick={onHide}
        >
          Siguiente
        </Button>
        <div className="my-4 d-flex justify-content-center reset-password-btn driver">
          <Button
            variant="outline"
            className="text-black"
            onClick={() => handleShow()}
          >
            Omitir
          </Button>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Omitir</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center p-5">
            Por temas de seguridad no podrás usar algunas funciones de Briiing
            si no tienes tu información al día.
            <br />
            ¿Estas seguro de esto?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No, quiero completar mis datos.
            </Button>
            <Button
              variant="primary"
              onClick={() => history.push('/driver/home')}
            >
              Si, quiero omitir.
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </div>
  );
};

const setInitialValues = props => {
  return props.myState;
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    driverAttributes: Yup.object().shape({
      licenseType: Yup.string().required('Debes elegir un tipo de licencia'),
      backLicense: Yup.mixed().required('Debes adjuntar una imagen'),
      frontLicense: Yup.mixed().required('Debes adjuntar una imagen'),
      frontIdentity: Yup.mixed().required('Debes adjuntar una imagen'),
      backIdentity: Yup.mixed().required('Debes adjuntar una imagen'),
      backgroundCertificate: Yup.mixed().required(
        'Debes adjuntar un archivo pdf'
      )
    })
  })
});

const handleSubmit = (values, { props }) => {
  const { updateState, setStep, myState } = props;
  updateState(values);
  setStep(3);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit
})(LicenceForm);
