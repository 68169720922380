import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Button } from 'react-bootstrap';
import { sendAlert } from '../../../actions/utils';
import {
  DefaultHeader,
  DefaultModal,
  SpinnerLoader
} from '../../../components';
import basicSolicitude from '../../../components/Solicitude/basicSolicitude';
import SolicitudeMainInfo from '../../../components/Driver/DriverShow/SolicitudeMainInfo';
import StatusBox from '../../../components/Driver/DriverShow/StatusBox';
import DrivertItemDescription from '../../../components/Driver/DriverShow/DrivertItemDescription';
import {
  showShippingRequest,
  updateShippingRequest
} from '../../../requests/shippings';
import QuestionAndAnswers from '../../../components/Client/ClientShow/QuestionAndAnswers';

const DriverShipmentShow = ({ match }) => {
  const [shipping, setShipping] = useState(basicSolicitude);
  const [isFetching, setIsFetching] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = match.params;
  const modelUrl = '/driver/shipments';

  const fetchShipping = () => {
    setIsFetching(true);
    showShippingRequest(id, {
      dispatch,
      successCallback: response => {
        setShipping(camelCaseRecursive(response.data));
        setIsFetching(false);
      }
    });
  };

  useEffect(fetchShipping, []);

  const handleSuccessDeactivateSolicitude = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message:
          'Haz eliminado el envío con éxito. Haz sido calificado con una estrella.'
      })
    );
    history.push(modelUrl);
  };

  const handleDeactivateOffer = () => {
    updateShippingRequest(id, {
      dispatch,
      params: {
        active: false
      },
      successCallback: handleSuccessDeactivateSolicitude
    });
    setModalShow(false);
  };

  if (isFetching) {
    return <SpinnerLoader animation="border" variant="primary" />;
  }

  const setBreadCrumb = () => {
    switch (location.state) {
      case 'fromDashboard':
        return [
          {
            key: 1,
            name: 'Inicio',
            href: '/driver/home'
          },
          {
            key: 2,
            name: 'Detalle envío',
            href: '/driver/home'
          }
        ];
      default:
        return [
          {
            key: 1,
            name: 'Mis Briiing',
            href: '/driver/shipments'
          },
          {
            key: 2,
            name: 'Detalle'
          }
        ];
    }
  };

  const {
    id: solicitudeId,
    pickupPersonName,
    pickupPersonPhone,
    deliveryPersonName,
    deliveryPersonPhone,
    client,
    description,
    deliveryAddressAttributes,
    pickupAddressAttributes,
    itemsAttributes,
    selectedQuotation,
    statusTranslated,
    status
  } = Boolean(shipping) && shipping.solicitude;
  const { tripId } = shipping;
  const shouldRenderCancelButton = ![
    'delivered',
    'finished',
    'cancelled'
  ].includes(status);

  return (
    <>
      <DefaultHeader breadcrumb={setBreadCrumb()} />
      <div className="driver-solicitude-show opacity-animation">
        <Row className="d-flex align-items-start justify-content-between row">
          <SolicitudeMainInfo
            id={id}
            pickupAddressAttributes={pickupAddressAttributes}
            deliveryAddressAttributes={deliveryAddressAttributes}
            itemsAttributes={itemsAttributes}
            client={client}
            deliveryPersonPhone={deliveryPersonPhone}
            comments={description}
            status={statusTranslated}
            ammount={selectedQuotation?.parsedDriverCommissionAmount}
            tripId={tripId}
          />
          <StatusBox
            pickupPersonName={pickupPersonName}
            pickupPersonPhone={pickupPersonPhone}
            deliveryPersonName={deliveryPersonName}
            deliveryPersonPhone={deliveryPersonPhone}
            pickupAddressAttributes={pickupAddressAttributes}
            deliveryAddressAttributes={deliveryAddressAttributes}
            shipping={shipping}
            setShipping={setShipping}
          />
        </Row>
        <hr />
        <h5 className="my-4">Objetos ({itemsAttributes?.length})</h5>
        <Row>
          <div className="d-flex total-items-container p-3">
            {itemsAttributes?.map(item => (
              <DrivertItemDescription
                key={item.name}
                images={item.itemImages}
                title={item.name}
                height={item.height}
                length={item.length}
                width={item.width}
                weight={item.weight}
              />
            ))}
          </div>
        </Row>
        <hr className="my-4" />
        <QuestionAndAnswers
          solicitudeId={solicitudeId}
          disable={!shouldRenderCancelButton}
        />
        {shouldRenderCancelButton && (
          <Row className="my-4 d-flex justify-content-end">
            <Button
              variant="danger"
              onClick={() => setModalShow(true)}
              className="ml-auto px-4"
            >
              Cancelar envío
            </Button>
            <DefaultModal
              title="Cancelar envío"
              body={
                <>
                  <p className="text-center mt-4">
                    Si cancelas el envío seras calificado con una estrella.
                  </p>
                  <p className="text-center mb-3">
                    ¿Estás seguro de cancelar este envío?
                  </p>
                </>
              }
              show={modalShow}
              handleClose={() => setModalShow(false)}
              handleConfirm={handleDeactivateOffer}
              titleBtnClose="Cancelar"
              titleBtnSave="Confirmar"
            />
          </Row>
        )}
      </div>
    </>
  );
};

export default DriverShipmentShow;
