/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useRef } from 'react';
import { Image, Row, Col, Form, Modal, Button } from 'react-bootstrap';
import defaultImg from '../../../assets/images/default.png';
import IcoMoon from '../Icon';
import './styles.scss';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
const ImageCropper = ({
  imageUrl,
  accept,
  onChange,
  label,
  numberId,
  abbr,
  iconComponent,
  fileName,
  classNameImg,
  classNameEmpty,
  error,
  touched,
  isForStepRegister,
  previewFile
}) => {
  const cropperRef = useRef();
  const [url, setUrl] = useState(imageUrl);
  const [name, setName] = useState('Elegir Imagen');
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setModal(false);
  const handleShow = () => setModal(true);
  const _handleImageChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setImage(reader.result);
      setModal(true);
      // setUrl(reader.result);
      // setName(file.name);
      setName(file.name);
    };
    reader.readAsDataURL(file);
    onChange(file);
  };

  const clearImage = () => {
    setUrl('');
    setName('Elegir Imagen');
    if (abbr) onChange('');
    else onChange({});
  };

  useEffect(() => setUrl(imageUrl), [imageUrl]);

  useEffect(() => {
    if (fileName) setName(fileName);
  }, [fileName]);

  useEffect(() => {
    if (previewFile?.type) {
      const reader = new FileReader();
      const file = previewFile;
      reader.onloadend = () => {
        setUrl(reader.result);
        setName(file.name);
      };
      reader.readAsDataURL(file);
    }
  }, [previewFile]);
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      setUrl(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setModal(false);
      onChange(
        dataURLtoFile(
          cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
          name
        )
      );
      // setUrl(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };
  return (
    <Row>
      <Col md={12} className="container-input-img">
        {label && (
          <Form.Label className="w-100 mb-3">
            <div
              className={`d-flex justify-content-between align-items-center ${
                isForStepRegister ? '' : 'px-4'
              } `}
            >
              <div>
                {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
              </div>
              {isForStepRegister ? (
                <div className={`circle-image ${url ? 'loaded' : 'load-btn'}`}>
                  <IcoMoon icon="image1" size={20} />
                </div>
              ) : (
                iconComponent
              )}
            </div>
          </Form.Label>
        )}
        {url ? (
          <div className="custom-single-file-img mb-2">
            <Image
              className={classNameImg}
              src={url || imageUrl || defaultImg}
              fluid
            />
          </div>
        ) : (
          <>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={`inputGroupFile${numberId}`} className="img-box">
              <div
                className={`box box-single ${classNameEmpty} ${
                  error && touched ? 'box-required' : ''
                }`}
              >
                <IcoMoon icon="add" size={40} />
              </div>
            </label>
          </>
        )}
        <div className="input-group mb-3">
          <div className="custom-file d-flex justify-content-center">
            <input
              type="file"
              className="custom-file-input"
              id={`inputGroupFile${numberId}`}
              aria-describedby={`inputGroupFileAddon${numberId}`}
              onChange={e => _handleImageChange(e)}
              accept={accept}
            />
            <div className="d-flex flex-column align-items-center">
              {error && touched && (
                <Form.Text className="text-danger my-3">{error}</Form.Text>
              )}
              <div className="name-container d-flex align-items-center">
                <label
                  className="name-img text-truncate mb-0"
                  htmlFor="inputGroupFile01"
                >
                  {name}
                </label>
                {url && (
                  <IcoMoon
                    icon="close"
                    size={20}
                    onClick={() => clearImage()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Modal
        show={modal}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Recortar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center p-5">
          <Cropper
            ref={cropperRef}
            style={{ height: 400, width: '100%' }}
            zoomTo={0.5}
            initialAspectRatio={1}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            guides
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={getCropData}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};

ImageCropper.defaultProps = {
  onChange: () => {}
};

export default ImageCropper;
