/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Fade from 'react-reveal/Fade';
import { FormikInput } from '../../components';
import { requestSignIn } from '../../actions/auth';
import WrapperSession from '../../components/Auth/WrapperSession';
import client from '../../assets/images/svg/client.svg';
import driver from '../../assets/images/svg/driver.svg';
import bgDriver from '../../assets/images/background_driver_blend.png';
import bgClient from '../../assets/images/background_client_blend.png';

const ClientLogin = props => {
  console.log(props);
  const [toggle, setToggle] = useState(false);
  const { onHide, isValid, errors, touched } = props;
  const loginRole = localStorage.getItem('loginRole');
  const [role, setRole] = useState(loginRole);

  const isClient = role === 'client';

  if (!loginRole) {
    return <Redirect to="/" />;
  }

  return (
    <WrapperSession>
      <Form className="login_box">
        <img
          src={isClient ? bgClient : bgDriver}
          alt="bg-client"
          className={`bg-image ${isClient ? 'color-client' : 'color-driver'}`}
        />
        <Fade top duration={400}>
          <div className="logo shadow-orange">
            <Image
              src={isClient ? client : driver}
              alt="Logo"
              style={{ width: '50px' }}
            />
          </div>
        </Fade>
        <Fade bottom duration={400}>
          <h5 className="d-flex justify-content-center pb-2">
            <button
              onClick={() => {
                localStorage.setItem('loginRole', 'client');
                setRole('client');
              }}
              className={`border-0 p-2 w-100 ${isClient ? 'bg-success' : ''}`}
            >
              Clientes
            </button>
            <button
              onClick={() => {
                localStorage.setItem('loginRole', 'driver');
                setRole('driver');
              }}
              className={`border-0 p-2 w-100 ${isClient ? '' : 'bg-success'}`}
            >
              Briiinger
            </button>
          </h5>

          <h6 className="text-center mb-3">Inicio de sesión</h6>
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Correo"
                placeholder="Nombre@correo.com"
                inputType="email"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="password">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType={`${toggle ? 'text' : 'password'}`}
                abbr
                label="Clave"
                icon="eye"
                classNameIcon={isClient ? 'client' : 'driver'}
                iconClick={() => setToggle(!toggle)}
                placeholder="Ingresa tu clave"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Button
            variant={isClient ? 'secondary' : 'primary'}
            size="lg"
            className={`btn login-btn ${isClient ? 'client' : 'driver'}-btn`}
            block
            type="submit"
            disabled={!isValid || props.isSubmitting}
            onClick={() => {
              //onHide();
            }}
          >
            {props.isSubmitting ? 'Ingresando...' : 'Iniciar Sesión'}
          </Button>
          <Link to="/register" className="register">
            <div
              className={`d-flex mt-2 justify-content-center align-items-center register-btn register-btn-${
                isClient ? 'client' : 'driver'
              }`}
            >
              Registrarme
            </div>
          </Link>
          <div
            className={`d-flex my-4 justify-content-center reset-password-btn ${
              isClient ? 'client' : 'driver'
            }`}
          >
            <Link to="/recover_password" className="text-black">
              Olvidé mi contraseña
            </Link>
          </div>
        </Fade>
      </Form>
    </WrapperSession>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  email: '',
  password: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio'),
  password: Yup.string().required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  dispatch(
    requestSignIn({
      user: {
        email: values.email,
        password: values.password
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(ClientLogin)
  )
);
