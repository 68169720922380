import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import IcoMoon from '../Utils/Icon';
import { sendAlert } from '../../actions/utils';
import FormikAddressAutocompleteInput from '../Utils/Input/FormikAddressAutocompleteInput';
import { getCommuneByName } from '../../requests/communes';
import { DefaultModal } from '../Utils/Modal';
import GooglePointMap from '../Utils/GoogleMap/GooglePointMap';
import { FormikInput } from '../Utils/Input';

const AddressFields = ({ values, errors, touched, modelName, placeholder }) => {
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [showMap, setShowMap] = useState(false);
  const [hasCoordinates, setHasCoordinates] = useState(false);

  useEffect(() => {
    setHasCoordinates(!!getIn(values, `${modelName}[latitude]`));
  }, [values]);

  const handleSuccessCommune = ({ response, place }) => {
    const lon = place.geometry.location.lng();
    const lat = place.geometry.location.lat();
    setFieldValue(`${modelName}[streetName]`, place.formatted_address);
    setFieldValue(`${modelName}[communeId]`, response.data[0].id);
    setFieldValue(`${modelName}[latitude]`, lat);
    setFieldValue(`${modelName}[longitude]`, lon);
  };

  const handleFailureCommune = r => {
    const errorMessage = r?.response?.data?.message;
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    setFieldValue(`${modelName}[streetName]`, '');
    setFieldValue(`${modelName}[communeId]`, '');
    setFieldValue(`${modelName}[latitude]`, '');
    setFieldValue(`${modelName}[longitude]`, '');
    setFieldValue(`${modelName}[isValidAddress]`, false);
  };

  const handleInputChange = event => {
    setFieldValue(`${modelName}[streetName]`, event.target.value);
    setFieldValue(`${modelName}[latitude]`, '');
    setFieldValue(`${modelName}[longitude]`, '');
    setFieldValue(`${modelName}[isValidAddress]`, false);
  };

  const handlePlaceSelected = place => {
    setFieldValue(`${modelName}[isValidAddress]`, true);
    if (place.geometry) {
      // save commmune
      let nameCommune = '';
      place.address_components.forEach(element => {
        if (element.types[0] === 'administrative_area_level_3') {
          nameCommune = element.short_name;
        }
      });
      // get commune by name from backend
      getCommuneByName({
        dispatch,
        params: {
          name: nameCommune
        },
        successCallback: response => {
          handleSuccessCommune({ response, place });
        },
        failureCallback: response => {
          handleFailureCommune(response);
        }
      });
    }
  };

  const handleCLickIconMap = () => {
    if (hasCoordinates) {
      setShowMap(true);
    }
  };

  return (
    <>
      <Row className="d-flex justify-content-between align-items-start ">
        <Col sm={12} md={10}>
          <Field name={`${modelName}[streetName]`}>
            {({ field }) => (
              <FormikAddressAutocompleteInput
                {...field}
                abbr
                inputType="text"
                label="Dirección"
                placeholder={placeholder || 'Dirección'}
                setFieldValue={setFieldValue}
                onChange={handleInputChange}
                onBlur={handleInputChange}
                handlePlaceSelected={handlePlaceSelected}
                values={values}
                error={
                  getIn(errors, field.name) ||
                  getIn(errors, `${modelName}[isValidAddress]`)
                }
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col
          sm={12}
          md={2}
          className="d-flex flex-row justify-content-start justify-content-md-end align-items-end "
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 100 }}
            overlay={<Tooltip id="button-tooltip">Ver Mapa</Tooltip>}
          >
            <div
              className={`view-map-button ${hasCoordinates &&
                'view-map-button-active'}`}
              aria-hidden="true"
              onClick={handleCLickIconMap}
            >
              <IcoMoon size="20" icon="location1" />
            </div>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={10}>
          <Field name={`${modelName}[numero]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="text"
                label="Numero Depto / Oficina"
                placeholder={placeholder || 'Numero Depto / Oficina'}
              />
            )}
          </Field>
        </Col>
      </Row>
      <DefaultModal
        title={getIn(values, `${modelName}[streetName]`)}
        body={
          <div style={{ width: '100%', height: '500px' }}>
            <GooglePointMap
              address={getIn(values, `${modelName}[streetName]`)}
            />
          </div>
        }
        show={showMap}
        handleClose={() => setShowMap(false)}
        handleConfirm={() => setShowMap(false)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        size="xl"
        noButtons
      />
    </>
  );
};

export default AddressFields;
