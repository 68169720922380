import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Form, withFormik, getIn } from 'formik';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { BasicProfileFields } from '../../../components/Profile';
import VehiclesAttributes from '../../../components/Vehicles/VehiclesAttributes';
import {
  FormikSelect,
  UploadImage,
  IcoMoon,
  UploadFile,
  FormikInput
} from '../../../components';
import { licenses, accountTypes } from './formOptions';

const ProfileDriverForm = ({
  modelName,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  user,
  onRequest,
  bankList,
  submitCount
}) => {
  const {
    licenseType,
    frontLicense,
    frontIdentity,
    backIdentity,
    backLicense,
    backgroundCertificate,
    deleteVehiclesPhotos,
    deletePadron,
    deletePermiso,
    bankAccountAttributes
  } = values.user?.driverAttributes;

  const location = useLocation();

  useEffect(() => {
    if (location.state?.quotation)
      setFieldValue('user[fromQuotation]', location.state.quotation);
  }, [values]);
  useEffect(() => {
    console.log(errors);
    if (errors.user) {
      const errorKeys = Object.keys(errors.user);

      if (!!errorKeys.length && submitCount > 0) {
        console.log(`name["${errorKeys[0]}"]`);
        const firstElement = document.querySelector(
          `[name*="${errorKeys[0]}"]`
        );
        if (firstElement !== document.activeElement) {
          firstElement.focus();
        }
      }
    }
  }, [submitCount]);
  console.log(values);
  return (
    <Form>
      <BasicProfileFields
        modelName={modelName}
        errors={errors}
        touched={touched}
        user={user}
        setFieldValue={setFieldValue}
      />
      <hr className="my-4" />
      <h5 className="mb-4">Datos del Vehículo</h5>
      <VehiclesAttributes
        modelName={modelName}
        vehiclesAttributes={values.user?.driverAttributes?.vehiclesAttributes}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        deleteVehiclesPhotos={deleteVehiclesPhotos}
        deletePadron={deletePadron}
        deletePermiso={deletePermiso}
        setFieldTouched={setFieldTouched}
      />
      <hr className="mt-5 mb-4" />
      <h5 className="mb-4">Tus datos de Transporte</h5>
      <Row>
        <Col md={4}>
          <Field name={`${modelName}[licenseType]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Licencia"
                placeholder="Seleccionar licencia"
                options={licenses}
                defaultValue={licenseType}
                onChange={data =>
                  setFieldValue(field.name, data ? data.value : '')
                }
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mt-4 ml-xl-n4">
        <Col md={4}>
          <Field name={`${modelName}[frontLicense]`}>
            {({ field }) => {
              return (
                <UploadImage
                  {...field}
                  abbr
                  numberId="02"
                  label="Licencia frontal"
                  iconComponent={
                    <div className="circle-image">
                      <IcoMoon icon="image1" size={20} />
                    </div>
                  }
                  classNameImg="img-license-input"
                  classNameEmpty="empty-img-input"
                  accept="image/jpg, image/png, image/jpeg"
                  setFieldValue={setFieldValue}
                  imageUrl={getIn(frontLicense, 'fileUrlMinified')}
                  fileName={getIn(frontLicense, 'filename')}
                  onChange={images => setFieldValue(field.name, images)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              );
            }}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[backLicense]`}>
            {({ field }) => {
              return (
                <UploadImage
                  {...field}
                  abbr
                  numberId="03"
                  label="Licencia trasera"
                  iconComponent={
                    <div className="circle-image">
                      <IcoMoon icon="image1" size={20} />
                    </div>
                  }
                  classNameImg="img-license-input"
                  classNameEmpty="empty-img-input"
                  accept="image/jpg, image/png, image/jpeg"
                  setFieldValue={setFieldValue}
                  imageUrl={getIn(backLicense, 'fileUrlMinified')}
                  fileName={getIn(backLicense, 'filename')}
                  onChange={images => setFieldValue(field.name, images)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              );
            }}
          </Field>
        </Col>
        <Col md={4} className="mt-3 mt-md-0">
          <Field name={`${modelName}[backgroundCertificate]`}>
            {({ field }) => {
              return (
                <div>
                  <UploadFile
                    {...field}
                    abbr
                    numberId="02"
                    label={'Certificado Antecedentes'}
                    added={
                      <small className="ps-2">
                        Para obtener este documento haz{' '}
                        <a
                          target="_blank"
                          href="https://www.chileatiende.gob.cl/fichas/3442-certificado-de-antecedentes-e-informes-de-inhabilidades"
                          rel="noreferrer"
                        >
                          <strong>click aqui</strong>
                        </a>
                        .
                      </small>
                    }
                    iconComponent={
                      <div className="circle-image">
                        <IcoMoon icon="files" size={20} />
                      </div>
                    }
                    accept="application/pdf"
                    setFieldValue={setFieldValue}
                    fileName={getIn(backgroundCertificate, 'filename')}
                    onChange={images => setFieldValue(field.name, images)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                </div>
              );
            }}
          </Field>
        </Col>
      </Row>
      <Row className="mt-4 ml-xl-n4">
        <Col md={4}>
          <Field name={`${modelName}[frontIdentity]`}>
            {({ field }) => {
              return (
                <UploadImage
                  {...field}
                  abbr
                  numberId="04"
                  label="Cédula de identidad (frontal)"
                  iconComponent={
                    <div className="circle-image">
                      <IcoMoon icon="image1" size={20} />
                    </div>
                  }
                  classNameImg="img-license-input"
                  classNameEmpty="empty-img-input"
                  accept="image/jpg, image/png, image/jpeg"
                  setFieldValue={setFieldValue}
                  imageUrl={getIn(frontIdentity, 'fileUrlMinified')}
                  fileName={getIn(frontIdentity, 'filename')}
                  onChange={images => setFieldValue(field.name, images)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              );
            }}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[backIdentity]`}>
            {({ field }) => {
              return (
                <UploadImage
                  {...field}
                  abbr
                  numberId="05"
                  label="Cédula de identidad (reverso)"
                  iconComponent={
                    <div className="circle-image">
                      <IcoMoon icon="image1" size={20} />
                    </div>
                  }
                  classNameImg="img-license-input"
                  classNameEmpty="empty-img-input"
                  accept="image/jpg, image/png, image/jpeg"
                  setFieldValue={setFieldValue}
                  imageUrl={getIn(backIdentity, 'fileUrlMinified')}
                  fileName={getIn(backIdentity, 'filename')}
                  onChange={images => setFieldValue(field.name, images)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              );
            }}
          </Field>
        </Col>
      </Row>
      <hr className="mt-5 mb-4" />
      <h5 className="mb-4">Tus datos Bancarios</h5>
      <Row>
        <Col md={4}>
          <Field name={`${modelName}[bankAccountAttributes][rut]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="RUT"
                placeholder="Ej: 12.345.678-9"
                inputType="text"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[bankAccountAttributes][bankId]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Banco"
                placeholder="Seleccionar banco"
                options={bankList}
                defaultValue={bankAccountAttributes?.bankId}
                onChange={data =>
                  setFieldValue(field.name, data ? data.value : '')
                }
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[bankAccountAttributes][accountType]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Tipo de cuenta"
                placeholder="Escoge un cuenta"
                options={accountTypes}
                defaultValue={bankAccountAttributes?.accountType}
                onChange={data =>
                  setFieldValue(field.name, data ? data.value : '')
                }
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[bankAccountAttributes][accountNumber]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="N° de cuenta"
                placeholder="Ingresa N° de cuenta"
                inputType="text"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row />

      <Row>
        <Col xs={8} sm={6} md={4} className="ml-auto">
          <Button
            variant="success"
            type="submit"
            className="mt-4 mt-md-0"
            disabled={onRequest}
            block
          >
            {onRequest && (
              <Spinner
                as="span"
                animation="border"
                className="mr-2"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { user, vehiclesExist } = props;

  const emptyBackLicense =
    Object.entries(user?.driverAttributes?.backLicenseImg || {}).length === 0;
  const emptyFrontLicense =
    Object.entries(user?.driverAttributes?.frontLicenseImg || {}).length === 0;
  const emptyCertificate =
    Object.entries(user?.driverAttributes?.backgroundCertificateFile || {})
      .length === 0;
  const emptyFrontIdentity =
    Object.entries(user?.driverAttributes?.frontIdentityImg || {}).length === 0;
  const emptyBackIdentity =
    Object.entries(user?.driverAttributes?.backIdentityImg || {}).length === 0;

  return {
    user: {
      avatar: {},
      email: user.email || '',
      driverAttributes: {
        id: user.driverAttributes?.id || '',
        name: user.driverAttributes?.name || '',
        lastName: user.driverAttributes?.lastName || '',
        phone: user.driverAttributes?.phone || '',
        description: user.driverAttributes?.description || '',
        licenseType: user.driverAttributes?.licenseType || '',
        deleteVehiclesPhotos: [],
        deletePadron: [],
        deletePermiso: [],
        bankAccountAttributes: {
          accountNumber:
            user?.driverAttributes?.bankAccountAttributes?.accountNumber || '',
          accountType:
            user?.driverAttributes?.bankAccountAttributes?.accountType || '',
          bankId: user?.driverAttributes?.bankAccountAttributes?.bank?.id || '',
          rut: user?.driverAttributes?.bankAccountAttributes?.rut || ''
        },
        frontLicense: emptyFrontLicense
          ? ''
          : user.driverAttributes?.frontLicenseImg,
        backLicense: emptyBackLicense
          ? ''
          : user.driverAttributes?.backLicenseImg,
        frontIdentity: emptyFrontIdentity
          ? ''
          : user.driverAttributes?.frontIdentityImg,
        backIdentity: emptyBackIdentity
          ? ''
          : user.driverAttributes?.backIdentityImg,
        backgroundCertificate: emptyCertificate
          ? ''
          : user.driverAttributes?.backgroundCertificateFile,
        user_id: user.id || null,
        vehiclesAttributes: vehiclesExist
          ? [...user?.driverAttributes?.vehiclesAttributes]
          : [
              {
                car_make: '',
                car_model: '',
                car_year: '',
                license_plate: '',
                vehicle_type: '',
                height: '',
                length: '',
                width: '',
                photos: [],
                padron: '',
                permiso_circulacion: '',
                vehicle_photos: [],
                no_medidas: false
              }
            ]
      }
    }
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    driverAttributes: Yup.object().shape({
      name: Yup.string().required('Debes ingresar tu nombre'),
      lastName: Yup.string().required('Debes ingresar tu apellido'),
      phone: Yup.string()
        .required('Debes ingresar tu teléfono')
        .test('len', 'Teléfono incorrecto', val => val?.trim().length === 14),
      licenseType: Yup.string().required('Debes elegir un tipo de licencia'),
      backLicense: Yup.mixed().required('Debes adjuntar una imagen'),
      frontLicense: Yup.mixed().required('Debes adjuntar una imagen'),
      frontIdentity: Yup.mixed().required('Debes adjuntar una imagen'),
      backIdentity: Yup.mixed().required('Debes adjuntar una imagen'),
      backgroundCertificate: Yup.mixed().required(
        'Debes adjuntar un archivo pdf'
      ),
      vehiclesAttributes: Yup.array().of(
        Yup.object().shape({
          car_make: Yup.string().required(
            'Debes ingresar la marca de tu vehículo'
          ),
          car_model: Yup.string().required(
            'Debes ingresar el modelo de tu vehículo'
          ),
          car_year: Yup.number()
            .required('Debes ingresar el año de tu vehículo')
            .test(
              'len',
              'El año debe ser de 4 dígitos',
              val => val?.toString().length === 4
            )
            .min(1960, 'El año debe ser superior a la fecha ingresada')
            .max(new Date().getFullYear(), 'El año debe ser menor o actual'),
          license_plate: Yup.string().required(
            'Debes ingresar la patente de tu vehículo'
          ),
          vehicle_type: Yup.string().required(
            'Debes ingresar el tipo de vehículo'
          ),
          height: Yup.number().when('no_medidas', {
            is: val => {
              return !Boolean(val);
            },
            then: Yup.number()
              .required('Debes ingresar un alto')
              .typeError('Debes ingresar solo números')
              .positive('Debe ser mayor a 0')
          }),
          width: Yup.number().when('no_medidas', {
            is: val => {
              return !Boolean(val);
            },
            then: Yup.number()
              .required('Debes ingresar un ancho')
              .typeError('Debes ingresar solo números')
              .positive('Debe ser mayor a 0')
          }),
          length: Yup.number().when('no_medidas', {
            is: val => {
              return !Boolean(val);
            },
            then: Yup.number()
              .required('Debes ingresar un largo')
              .typeError('Debes ingresar solo números')
              .positive('Debe ser mayor a 0')
          })
        })
      )
    })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProfileDriverForm);
