const API_CONFIG = {
  domain:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_PRODUCTION_URL
      : process.env.REACT_APP_API_STAGING_URL,
  version: 'v1',
  url: () => API_CONFIG.domain + API_CONFIG.version,
  globalTimeout: 60 * 1000,
  timeoutMessage:
    'Está tardando demasiado, verifica tu conexión a internet e intenta nuevamente'
};

export default API_CONFIG;
