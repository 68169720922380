import React from 'react';
import { Row, Col } from 'react-bootstrap';
import IcoMoon from '../Utils/Icon/IcoMoon';
import DefaultImg from '../../assets/images/briiing-default.svg';
import { vehiclesTypes } from '../../screens/Driver/Profile/formOptions';
import ImageWithZoom from '../Utils/Image/ImageWithZoom';
import ChartDriver from './ChartDriver';
import AccountType from './AccountType';

const ProfileSecondCol = ({
  driver,
  description,
  vehiclesAttributes,
  bankAccountAttributes,

  frontLicenseUrl,
  frontLicenseImgFullRes,

  backLicenseUrl,
  backLicenseImgFullRes,

  frontIdentityUrl,
  frontIdentityImgFullRes,
  backIdentityUrl,
  backIdentityImgFullRes
}) => {
  const accountType = AccountType[bankAccountAttributes?.accountType];
  const hasIncompleteBankInfo =
    !bankAccountAttributes?.rut ||
    !accountType ||
    !bankAccountAttributes?.accountNumber ||
    !bankAccountAttributes?.bank?.name;

  return (
    <>
      <Col md={7} xl={8} className="right-animation">
        <div className="bg-description">
          <div className="box-description">
            <h6>Descripción</h6>
            <p className="text-description">
              {description || 'Aún no hay una descripción'}
            </p>
          </div>
          <div className="bg-description-driver mt-4">
            {driver && (
              <>
                <div className="box-description">
                  <h6 className="mb-3">Datos bancarios</h6>
                  {hasIncompleteBankInfo ? (
                    'Tus datos bancarios están incompletos'
                  ) : (
                    <Col lg={12} xl={6}>
                      <ul>
                        <li>
                          <p>Rut:</p>{' '}
                          <span>{bankAccountAttributes?.rut || ' - '}</span>
                        </li>
                        <li>
                          <p>Tipo cuenta:</p>
                          <span>{accountType || ' - '}</span>
                        </li>
                        <li>
                          <p>Numero cta.:</p>{' '}
                          <span>
                            {bankAccountAttributes?.accountNumber || ' - '}
                          </span>
                        </li>
                        <li>
                          <p>Banco:</p>{' '}
                          <span>
                            {bankAccountAttributes?.bank?.name || ' - '}
                          </span>
                        </li>
                      </ul>
                    </Col>
                  )}
                </div>
                <div className="box-description mt-4">
                  <h6 className="mb-3">Mis autos</h6>
                  {vehiclesAttributes?.length === 0 &&
                    'No tienes agregado ningún vehículo'}
                  {vehiclesAttributes?.map((car, index) => (
                    <Row
                      className={index > 0 ? 'mt-4' : ''}
                      key={`car-${index.toString()}`}
                    >
                      <Col lg={12} xl={6}>
                        <ul>
                          <li>
                            <p>Marca:</p> <span>{car.carMake}</span>
                          </li>
                          <li>
                            <p>Modelo:</p> <span>{car.carModel}</span>
                          </li>
                          <li>
                            <p>Año:</p> <span>{car.carYear}</span>
                          </li>
                          <li>
                            <p>Patente:</p> <span>{car.licensePlate}</span>
                          </li>
                          <li>
                            <p className="min-width">Tipo de transporte: </p>
                            <span>
                              {
                                vehiclesTypes.find(
                                  object => object.value === car.vehicleType
                                ).label
                              }
                            </span>
                          </li>
                          {car.height && car.length && car.width && (
                            <li>
                              <p>Medidas: </p>
                              <span>
                                {car.height}cmX{car.length}cmX{car.width}cm
                              </span>
                            </li>
                          )}
                        </ul>
                      </Col>
                      <Col lg={12} xl={6} className="content-flex">
                        <div className="box-img-car mt-xl-n4">
                          {car?.vehiclePhotos.length > 0 ? (
                            <>
                              {car?.vehiclePhotos.map((img, indx) => (
                                <div
                                  key={`img-car-${indx.toString()}`}
                                  className="d-flex justify-content-center align-items-center img-car"
                                >
                                  <ImageWithZoom
                                    src={img?.fileUrlMinified || DefaultImg}
                                    fullSizeImage={img?.fileUrl || DefaultImg}
                                    alt={`vehicle-${indx}`}
                                    isPortrait
                                  />
                                </div>
                              ))}
                            </>
                          ) : (
                            '¡Agrega fotos de tu vehículo!'
                          )}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div>
              </>
            )}
            <div className="box-description mt-4">
              <Row
                className={`content-license ${
                  !frontIdentityUrl && !backIdentityUrl ? 'empty' : ''
                }`}
              >
                <Col lg={12} xl={3} className="pl-xl-0 mb-2 mb-xl-0">
                  <h6 className="ml-xl-n2">Mi Cédula</h6>
                </Col>
                <Col lg={12} xl={8} className="d-flex justify-content-center">
                  <div className="box-img-license">
                    {frontIdentityUrl || backIdentityUrl ? (
                      <>
                        {frontIdentityUrl && (
                          <div className="d-flex justify-content-center align-items-center img-car">
                            <ImageWithZoom
                              className="img-license"
                              src={frontIdentityUrl || DefaultImg}
                              alt="front_identity_img"
                              isPortrait
                              fullSizeImage={frontIdentityImgFullRes}
                            />
                          </div>
                        )}
                        {backIdentityUrl && (
                          <div className="d-flex justify-content-center align-items-center img-car">
                            <ImageWithZoom
                              className="img-license"
                              src={backIdentityUrl || DefaultImg}
                              alt="back_identity_img"
                              isPortrait
                              fullSizeImage={backIdentityImgFullRes}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <span className="ml-xl-5">
                        ¡Agrega fotos de tu cédula!
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            {driver && (
              <>
                <div className="box-description mt-4">
                  <Row
                    className={`content-license ${
                      !frontLicenseUrl && !backLicenseUrl ? 'empty' : ''
                    }`}
                  >
                    <Col lg={12} xl={3} className="pl-xl-0 mb-2 mb-xl-0">
                      <h6 className="ml-xl-n2">Mi Licencia</h6>
                    </Col>
                    <Col
                      lg={12}
                      xl={8}
                      className="d-flex justify-content-center"
                    >
                      <div className="box-img-license">
                        {frontLicenseUrl || backLicenseUrl ? (
                          <>
                            {frontLicenseUrl && (
                              <div className="d-flex justify-content-center align-items-center img-car">
                                <ImageWithZoom
                                  className="img-license"
                                  src={frontLicenseUrl || DefaultImg}
                                  alt="front_license_img"
                                  isPortrait
                                  fullSizeImage={frontLicenseImgFullRes}
                                />
                              </div>
                            )}
                            {backLicenseUrl && (
                              <div className="d-flex justify-content-center align-items-center img-car">
                                <ImageWithZoom
                                  className="img-license"
                                  src={backLicenseUrl || DefaultImg}
                                  alt="back_license_img"
                                  isPortrait
                                  fullSizeImage={backLicenseImgFullRes}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <span className="ml-xl-5">
                            ¡Agrega fotos de tu licencia!
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="box-description backgroundCertificate mt-4">
                  <Row>
                    <Col className="d-flex align-items-center" lg={12} xl={6}>
                      <h6 className="mt-1">Certificado de Antecedentes</h6>
                    </Col>
                    <Col
                      lg={12}
                      xl={6}
                      className="d-flex justify-content-center justify-content-xl-start align-items-center"
                    >
                      {Object.entries(driver?.backgroundCertificateFile)
                        .length > 0 ? (
                        <a
                          target="_blank"
                          className="font-weight-bold mt-2 mt-xl-0 link-primary"
                          rel="noopener noreferrer"
                          href={
                            driver?.backgroundCertificateFile?.fileUrl || ''
                          }
                        >
                          <div className="d-flex align-items-center justify-content-center justify-content-xl-start">
                            <span className="certificate-text">
                              {driver?.backgroundCertificateFile?.filename}
                            </span>
                            <IcoMoon icon="eye" className="ml-n1" size={20} />
                          </div>
                        </a>
                      ) : (
                        <span className="mt-3 ml-xl-n3 mt-xl-0 text-center">
                          ¡Agrega tu certificado de antecedentes!
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        </div>
        {driver && <ChartDriver values={driver.chartData} />}
      </Col>
    </>
  );
};

export default ProfileSecondCol;
