import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Spinner, Row, Button, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import basicSolicitude from '../../../components/Solicitude/basicSolicitude';
import { createPaymentRequest } from '../../../requests/payments';
import { DefaultHeader, ExpiredSolicitudeHeader } from '../../../components';
import { showSolicitudeRequest } from '../../../requests/solicitudes';
import { sendAlert } from '../../../actions/utils';
import { showQuotationRequest } from '../../../requests/quotations';

const ClientSolicitudePayment = ({ match }) => {
  const [solicitude, setSolicitude] = useState(basicSolicitude);
  const [isFetching, setIsFetching] = useState(false);
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const { id } = match.params;
  const { quotationId } = match.params;
  const _isMounted = useRef(true);
  const modelUrl = '/client/solicitudes';
  const {
    hasQuotation,
    selectedQuotation,
    status,
    statusTranslated: translatedStatus,
    endDate,
    startDate,
    description,
    deliveryAddressAttributes,
    pickupAddressAttributes,
    itemsAttributes,
    pickupOption,
    deliveryOption,
    expired
  } = Boolean(solicitude) && solicitude;
  const { driver, paid } = Boolean(selectedQuotation) && selectedQuotation;

  const fetchSolicitude = () => {
    setIsFetching(true);
    showSolicitudeRequest(id, {
      dispatch,
      successCallback: response => {
        if (_isMounted.current) {
          setSolicitude(camelCaseRecursive(response.data));
        }
      }
    });
  };

  const [quotation, setQuotation] = useState({});
  const [urlAction, setUrlAction] = useState('');
  const [wsToken, setWsToken] = useState('');
  const handleToggleWebPay = async response => {
    const { url, token } = response.data;
    setUrlAction(url);
    setWsToken(token);
  };
  const handleSelectQuotation = async () => {
    const sendParams = snakeCaseKeys({ quotationId });
    showQuotationRequest(quotationId, {
      dispatch,
      successCallback: response => {
        if (_isMounted.current) {
          console.log('RESPONSE', response);

          setQuotation(camelCaseRecursive(response.data));
          setIsFetching(false);
        }
      }
    });
    createPaymentRequest({
      dispatch,
      params: sendParams,
      successCallback: handleToggleWebPay
    });
  };
  useEffect(() => {
    fetchSolicitude();
    handleSelectQuotation();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const setBreadCrumb = () => {
    switch (location.state) {
      case 'fromDashboard':
        return [
          {
            key: 1,
            name: 'Inicio',
            href: `/client/home`
          },
          { key: 2, name: 'Pagar' }
        ];
      case 'fromSolicitudesIndex':
        return [
          {
            key: 1,
            name: 'Mis Solicitudes',
            href: `/client/solicitudes`
          },
          { key: 2, name: 'Detalle solicitud' }
        ];
      case 'fromShipmentsDoneIndex':
        return [
          {
            key: 1,
            name: 'Envíos Realizados',
            href: `/client/shipments`
          },
          { key: 2, name: 'Detalle solicitud' }
        ];
      default:
        return [
          {
            key: 1,
            name: 'Inicio',
            href: `/client/home`
          },
          { key: 2, name: 'Detalle solicitud' }
        ];
    }
  };
  console.log(solicitude, quotation, match);
  return (
    <>
      {isFetching ? (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          <DefaultHeader breadcrumb={setBreadCrumb()} />

          <div
            className={`client-solicitude-show ${
              expired ? 'expired opacity-animation' : ''
            }`}
          >
            <h3>Total a Pagar: {quotation?.parsedCost}</h3>
            <h5 className="mt-4">Selecciona tu forma de pago:</h5>
            <Row className="mt-4">
              <Col xs lg="2">
                <form action={urlAction} method="POST">
                  <input type="hidden" name="token_ws" value={wsToken} />
                  <img src="/webpay.png" />
                  <Button
                    type="submit"
                    className="mt-4 mb-2"
                    disabled={expired}
                    variant="success"
                    block
                  >
                    Pagar Con Transbank
                  </Button>
                </form>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default ClientSolicitudePayment;
