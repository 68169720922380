import axios from 'axios';
import API_CONFIG from '../config/configurations';

const { url } = API_CONFIG;
const Getter = async (method, endpoint, data, type) => {
  const jwt = localStorage.getItem('jwt');

  const headers = {
    Authorization: `Bearer ${jwt}`,
    'Content-Type':
      type === 'form-data' ? 'multipart/form-data' : 'application/json',
    Accept: '*/*'
  };
  let inData = data;
  if (type === 'form-data') {
    inData = new FormData();
    Object.entries(data).forEach(([k, v]) => inData.append(k, v));
  }
  const config = {
    method,
    url: url() + endpoint,
    data: inData,
    headers
  };

  const res = await axios.request(config);
  return res.data;
};

export default Getter;
