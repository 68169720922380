import React from 'react';
import { Image } from 'react-bootstrap';
import logoClient from './logo-client.png';
import logoDriver from './logo-driver.png';

/**
 * @param {{loginRole: 'client' | 'driver'}} param0
 */
const MainLogo = ({ loginRole }) => (
  <Image
    src={loginRole === 'client' ? logoClient : logoDriver}
    alt="Logo Briiing!"
    className="d-inline-block align-top"
  />
);

export default MainLogo;
