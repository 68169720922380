import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Link } from '@mui/material';
import LicenseDescription from '../Client/ClientShow/LicenseDescription';
import DriverDescription from '../Client/ClientShow/DriverDescription';
import QuotationCardWrapper from './QuotationCardWrapper';
import { DefaultModal } from '../Utils/Modal';
import { sendAlert } from '../../actions/utils';
import { updateQuotationRequest } from '../../requests/quotations';

const QuotationCard = ({
  driver: isDriver,
  quotation,
  buttonOnClick,
  buttonText,
  actionUrl,
  fromSolicitude,
  token,
  buttonDisabled,
  withButton = true,
  withCurrency = true,
  split,
  expired,
  classNameContainer
}) => {
  const {
    driver,
    vehicle,
    comments: quotationComments,
    pickupAt,
    deliveryAt,
    parsedCost,
    parsedDriverCommissionAmount,
    tripCardInfo,
    id
  } = quotation;
  const {
    name: driverName,
    licenseTypeTranslated,
    numOfStars,
    avatar,
    description,
    cancellationFeePercentage: driverCancellationFee
  } = driver;
  const {
    vehicleType,
    carYear,
    carMake,
    carModel,
    licensePlate,
    height,
    length,
    width,
    vehiclePhotos
  } = vehicle;
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const modelUrl = '/driver/quotations';

  const handleSuccessDeactivateSolicitude = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Haz eliminado la oferta con éxito'
      })
    );
    history.push(modelUrl);
  };

  const handleDeactivateOffer = () => {
    updateQuotationRequest(id, {
      dispatch,
      params: {
        active: false
      },
      successCallback: handleSuccessDeactivateSolicitude
    });
    setModalShow(false);
  };

  const setVehicleDimension = () => {
    if (height && length && width) {
      return `${height}cm X ${length}cm X ${width}`;
    }
    return false;
  };

  return (
    <QuotationCardWrapper
      classNameContainer={classNameContainer}
      split={split}
      firstPart={
        <>
          <DriverDescription
            avatar={avatar}
            carImages={vehiclePhotos}
            driverName={driverName}
            stars={numOfStars}
            showW={quotation}
            title={vehicleType}
            carName={`${carMake} ${carModel} ${carYear}`}
            licensePlate={licensePlate}
            showWassapIcon={fromSolicitude}
            driver={driver}
            dimensions={setVehicleDimension()}
            driverCancellationFee={driverCancellationFee}
          />
          <LicenseDescription licenseType={licenseTypeTranslated} />
          <div className="mt-3 d-flex justify-content-between">
            <div>
              <h6>Descripción:</h6>
              <p>{description || 'Sin descripción'}</p>
            </div>
          </div>
        </>
      }
      secondPart={
        <>
          {!fromSolicitude && (
            <div className="mt-3">
              <h6>Viaje asociado:</h6>
              <p>{tripCardInfo || 'Sin viaje asociado'}</p>
            </div>
          )}
          <div className="mt-3">
            <h6>Comentario:</h6>
            <p>{quotationComments || 'Sin comentarios'}</p>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div>
              <p className="mt-2 font-weight-bold" style={{ fontSize: '16px' }}>
                Retiro: <span className="font-weight-light">{pickupAt}</span>
              </p>
              <p className="font-weight-bold" style={{ fontSize: '16px' }}>
                Entrega: <span className="font-weight-light">{deliveryAt}</span>
              </p>
            </div>
          </div>
          {withCurrency && (
            <div className="d-flex justify-content-center mt-4 mb-n1">
              <h6>
                {isDriver ? 'Ofreciste ' : 'Por pagar '}
                <span>
                  {isDriver ? parsedDriverCommissionAmount : parsedCost}
                </span>
              </h6>
            </div>
          )}
          {withButton && fromSolicitude && (
            <Button
              href=""
              className="mt-4 mb-2"
              disabled={expired}
              variant="success"
              block
              onClick={buttonOnClick}
            >
              {buttonText}
            </Button>
          )}
          {/* withButton && fromSolicitude && (
            <form action={actionUrl} method="POST">
              <input type="hidden" name="token_ws" value={token} />
              <Button
                type="submit"
                onClick={buttonOnClick}
                className="mt-4 mb-2"
                disabled={expired}
                variant="success"
                block
              >
                {buttonText}
              </Button>
            </form>
          ) */}
          {withButton && !fromSolicitude && (
            <Button
              onClick={buttonOnClick}
              className="mt-4 mb-2"
              variant="success"
              block
              disabled={buttonDisabled}
            >
              {buttonText}
            </Button>
          )}
          {!fromSolicitude && (
            <div className="d-flex justify-content-center mb-2">
              <Button
                variant="primary-outline-text"
                onClick={() => setModalShow(true)}
                className="mt-1"
              >
                Eliminar oferta
              </Button>
              <DefaultModal
                title="Eliminar oferta"
                body={
                  <p className="text-center mt-2">
                    ¿Estás seguro de eliminar esta oferta?
                  </p>
                }
                show={modalShow}
                handleClose={() => setModalShow(false)}
                handleConfirm={handleDeactivateOffer}
                titleBtnClose="Cancelar"
                titleBtnSave="Confirmar"
              />
            </div>
          )}
        </>
      }
    />
  );
};

export default QuotationCard;
