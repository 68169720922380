export const licenses = [
  { label: 'Clase B', value: 'class_b' },
  { label: 'Clase C', value: 'class_c' }
];

export const accountTypes = [
  { label: 'Cuenta Corriente', value: 'current_account' },
  { label: 'Cuenta Ahorro', value: 'savings_account' },
  { label: 'Cuenta Vista', value: 'view_account' }
];

export const vehiclesTypes = [
  { label: 'Auto', value: 'car' },
  { label: 'Camioneta con cabina', value: 'cabin_van' },
  { label: 'SUV', value: 'suv' },
  { label: 'Moto', value: 'motorcycle' },
  { label: 'Camión', value: 'truck' },
  { label: 'Furgón / Van', value: 'van' },
  { label: 'Bus', value: 'bus' },
  { label: 'Otro', value: 'other' }
];
