import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory, useLocation } from 'react-router-dom';
import { DefaultHeader } from '../../../components';
import { basicQuotation, QuotationForm } from '../../../components/Quotation';
import { createQuotationRequest } from '../../../requests/quotations';
import { sendAlert } from '../../../actions/utils';

const DriverQuotationNew = ({ match }) => {
  const { solicitudeId } = match.params;
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const modelName = 'quotation';
  const modelUrl = '/driver/quotations';

  const setBreadCrumb = () => {
    switch (location.state || location.hash.split('#')[1]) {
      case 'fromQuotation':
        return [
          {
            key: 1,
            name: 'Mis ofertas',
            href: `/driver/quotations`
          },
          {
            key: 2,
            name: 'Detalle oferta',
            href: `/driver/solicitudes/${solicitudeId}/offer`
          },
          { key: 3, name: 'Cotizador' }
        ];
      case 'fromDashboard':
        return [
          {
            key: 1,
            name: 'Inicio',
            href: '/driver/home'
          },
          {
            key: 2,
            name: 'Detalle solicitud',
            href: `/driver/solicitudes/${solicitudeId}/offer#fromDashboard`
          },
          {
            key: 3,
            name: 'Cotizador',
            href: `/driver/solicitudes/${solicitudeId}/offer`
          }
        ];
      default:
        return [
          {
            key: 1,
            name: 'Mis ofertas',
            href: `/driver/quotations`
          },
          {
            key: 2,
            name: 'Detalle oferta',
            href: `/driver/solicitudes/${solicitudeId}/offer`
          },
          { key: 3, name: 'Cotizador' }
        ];
    }
  };

  const handleSuccessCreateQuotation = () => {
    dispatch(
      sendAlert({ kind: 'success', message: 'Oferta creada con éxito' })
    );
    history.push(modelUrl);
  };
  const handleCreateQuotation = values => {
    setSending(true);
    const newParams = { quotation: { ...values.quotation, solicitudeId } };
    const sendParams = snakeCaseKeys(newParams);
    createQuotationRequest({
      dispatch,
      params: sendParams,
      successCallback: () => {
        setSending(false);
        handleSuccessCreateQuotation();
      }
    });
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <DefaultHeader breadcrumb={setBreadCrumb()} />
      <QuotationForm
        modelName={modelName}
        action="new"
        sending={sending}
        quotation={basicQuotation}
        solicitudeId={solicitudeId}
        formRequest={handleCreateQuotation}
      />
    </>
  );
};

export default DriverQuotationNew;
