/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useRef } from 'react';
import { Image, Row, Col, Form } from 'react-bootstrap';
import defaultImg from '../../../assets/images/default.png';
import IcoMoon from '../Icon';
import './styles.scss';

const UploadImage = ({
  imageUrl,
  accept,
  onChange,
  label,
  numberId,
  abbr,
  iconComponent,
  fileName,
  classNameImg,
  classNameEmpty,
  error,
  touched,
  isForStepRegister,
  previewFile
}) => {
  const [url, setUrl] = useState(imageUrl);
  const [name, setName] = useState('Elegir Imagen');
  const inputRef = useRef();
  const _handleImageChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setUrl(reader.result);
      setName(file.name);
    };
    reader.readAsDataURL(file);
    onChange(file);
  };

  const clearImage = () => {
    setUrl('');
    setName('Elegir Imagen');
    if (abbr) onChange('');
    else onChange({});
  };

  useEffect(() => setUrl(imageUrl), [imageUrl]);

  useEffect(() => {
    if (fileName) setName(fileName);
  }, [fileName]);

  useEffect(() => {
    if (previewFile?.type) {
      const reader = new FileReader();
      const file = previewFile;
      reader.onloadend = () => {
        setUrl(reader.result);
        setName(file.name);
      };
      reader.readAsDataURL(file);
    }
  }, [previewFile]);

  return (
    <Row>
      <Col md={12} className="container-input-img">
        {label && (
          <Form.Label className="w-100 mb-3">
            <div
              className={`d-flex justify-content-between align-items-center ${
                isForStepRegister ? '' : 'px-4'
              } `}
            >
              <div>
                {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
              </div>
              {isForStepRegister ? (
                <div className={`circle-image ${url ? 'loaded' : 'load-btn'}`}>
                  <IcoMoon icon="image1" size={20} />
                </div>
              ) : (
                iconComponent
              )}
            </div>
          </Form.Label>
        )}
        {url ? (
          <div className="custom-single-file-img mb-2">
            <Image
              className={classNameImg}
              src={url || imageUrl || defaultImg}
              fluid
            />
          </div>
        ) : (
          <>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={`inputGroupFile${numberId}`} className="img-box">
              <div
                className={`box box-single ${classNameEmpty} ${
                  error && touched ? 'box-required' : ''
                }`}
              >
                <IcoMoon icon="add" size={40} />
              </div>
            </label>
          </>
        )}
        <div className="input-group mb-3 d-flex flex-column align-items-center mw-100">
          <div className=" d-flex justify-content-center mw-100">
            <input
              type="file"
              className="custom-file-input"
              id={`inputGroupFile${numberId}`}
              aria-describedby={`inputGroupFileAddon${numberId}`}
              onChange={e => _handleImageChange(e)}
              accept="image/*"
            />
            <input
              type="file"
              style={{ display: 'none' }}
              className="custom-file-input"
              onChange={e => _handleImageChange(e)}
              ref={inputRef}
              capture="camera"
              accept="image/*"
            />
            <div className="mw-100 d-flex flex-column align-items-center">
              {error && touched && (
                <Form.Text className="text-danger my-3">{error}</Form.Text>
              )}
              <div className="name-container d-flex align-items-center mw-100">
                <label
                  className="name-img text-truncate mb-0"
                  htmlFor="inputGroupFile01"
                >
                  {name}
                </label>

                {url && (
                  <IcoMoon
                    icon="close"
                    size={20}
                    onClick={() => clearImage()}
                  />
                )}
              </div>
              {!url && (
                <button
                  className="btn btn-light d-md-none"
                  onClick={() => {
                    inputRef.current.click();
                  }}
                >
                  Usar camara
                </button>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

UploadImage.defaultProps = {
  onChange: () => {}
};

export default UploadImage;
