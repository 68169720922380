import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { Button } from 'react-bootstrap';
import { useFetchData } from '../../../hooks';
import { debounceIndexSolicitudesRequest } from '../../../requests/solicitudes';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader';
import ComponentDataTable from '../../../components/Utils/DataTable/index';
import { DefaultHeader, DefaultModal, IcoMoon } from '../../../components';
import { emptyFalseParamsRecursive } from '../../../services/utils';
import {
  Columns,
  conditionalDriverRows
} from '../../../components/Solicitude/ColumnsData';
import HeaderFilter from '../../../components/Utils/Filter/HeaderFilter';
import { driverDashboardRequest } from '../../../requests/dashboards';
import showMeRequest from '../../../requests/auth';
import {
  driverSolicitudeFilterParams,
  driverSolicitudeTabOptions
} from '../../../components/Solicitude/SolicitudeFilterParams';
import SidebarFilter from '../../../components/Utils/Filter/SidebarFilter';

const DriverDashboard = ({ user }) => {
  const [dashboardData, setDashBoardData] = useState({});
  const [profilePercentage, setProfilePercentage] = useState(100);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [hasNotVehicle, setHasNotVehicle] = useState(false);

  const customParamsRef = useRef({ ...driverSolicitudeFilterParams });
  const [customParams, setCustomParams] = useState({
    ...customParamsRef.current
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const modelUrl = '/driver';
  const userInfo = camelCaseRecursive(user);

  const handleOfferRowClicked = row => {
    history.push({
      pathname: `${modelUrl}/solicitudes/${row.id}/offer`,
      state: 'fromDashboard'
    });
  };

  const {
    data: solicitudes,
    moreData,
    isFetching,
    setMoreData,
    handleIndexRequest,
    totalData
  } = useFetchData({
    debouncedIndexRequest: debounceIndexSolicitudesRequest,
    withDataTable: true,
    fetchingErrorMessage: 'Oops, ocurrió un problema al buscar tus solicitudes',
    customParams: {
      excludeCurrentDriver: true,
      ...customParams
    }
  });

  const fetchProfileCompletePercentage = () => {
    showMeRequest({
      dispatch,
      successCallback: response => {
        setProfilePercentage(
          camelCaseRecursive(response.data)?.userInfo?.completenessDriver
        );
        const vehiclesLength =
          response.data.user_info.driver_attributes.vehicles_attributes
            ?.length > 0;
        setShowModal(!vehiclesLength);
        setHasNotVehicle(!vehiclesLength);
      }
    });
  };

  const handleFetchDashboard = () => {
    setOnRequest(true);
    driverDashboardRequest({
      dispatch,
      successCallback: response => {
        setOnRequest(false);
        setDashBoardData(camelCaseRecursive(response.data));
      }
    });
  };

  const goToProfileEdit = () => {
    history.push(`/driver/profile/edit`);
  };

  const filtersSanitize = (params = {}) => {
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  useEffect(handleFilterRequest, [customParams]);
  useEffect(fetchProfileCompletePercentage, []);
  useEffect(handleFetchDashboard, []);

  if (localStorage.getItem('isFirstLogin')) {
    return <Redirect to="/driver/stepsregister" />;
  }

  const toggleSidebar = () => setSidebarCollapsed(false);
  const closeSidebar = () => setSidebarCollapsed(true);

  const onFilterCallback = () => {
    handleIndexRequest(snakeCaseKeys(customParams));
  };

  return (
    <>
      <div className="data-table driver-dashboard">
        <div className="border-rounded p-0 mb-4">
          <DashboardHeader
            dashboardData={dashboardData}
            driver
            name={userInfo.driverAttributes?.name}
            countDays={userInfo.countDays}
            stars={userInfo.driverAttributes?.numOfStars}
            userInfo={userInfo}
            profilePercentage={profilePercentage}
          />
        </div>

        <div
          className="shadow-black border-rounded p-2 dt-driver-special-offers opacity-animation dt-driver-offers-dashboard"
          style={{ minHeight: '200px' }}
        >
          <h5 className="opacity-animation">
            Oportunidades especiales para ti
            <IcoMoon
              size="23"
              icon="info"
              tooltipText="Solicitudes para ofertar más óptimas para ti de acuerdo a tus viajes planificados"
              roundBorder
            />
          </h5>
          <ComponentDataTable
            noSpinner
            pagination={false}
            moreData={moreData}
            subHeader={false}
            onRequest={onRequest}
            columns={Columns({ hasNotVehicle, itIsSolicitude: true })}
            data={
              user.id !== 0 &&
              dashboardData?.associatedOpportunities?.data.filter(i => {
                return (
                  !user.client_attributes ||
                  user.client_attributes.id !== i.client.id
                );
              })
            }
            onRowClicked={handleOfferRowClicked}
          />
        </div>
        <SidebarFilter
          collapsed={sidebarCollapsed}
          customParams={customParams}
          setCustomParams={setCustomParams}
          closeSidebar={closeSidebar}
          setMoreData={setMoreData}
          onFilterCallback={onFilterCallback}
        />
        <DefaultHeader
          title="Solicitudes"
          body={
            <div>
              <Button
                variant="primary-outline"
                className="outline mr-2"
                onClick={toggleSidebar}
                size="lg"
              >
                Filtros
              </Button>
            </div>
          }
        />
      </div>
      <div className="data-table dt-client p-0 mb-4 dt-client-shippments dt-driver-offers-dashboard">
        <ComponentDataTable
          noSpinner
          moreData={moreData}
          totalRows={totalData}
          onRequest={isFetching}
          columns={Columns({ hasNotVehicle: false, itIsSolicitude: true })}
          conditionalRowStyles={conditionalDriverRows({ itIsSolicitude: true })}
          data={solicitudes
            .sort((a, b) => Number(a.hasQuotation) - Number(b.hasQuotation))
            .filter(i => {
              return (
                !user.client_attributes ||
                user.client_attributes.id !== i.client.id
              );
            })}
          onRowClicked={handleOfferRowClicked}
          resourceRequest={handleIndexRequest}
          customDatatableHeader={
            <HeaderFilter
              customParams={customParams}
              setCustomParams={setCustomParams}
              paramName="driverFilterQuotation"
              tabOptions={driverSolicitudeTabOptions}
            />
          }
        />
      </div>
      <DefaultModal
        title=""
        handleConfirm={() => goToProfileEdit()}
        titleBtnClose="Cancelar"
        titleBtnSave="Agregar auto"
        onlyConfirmBtn
        variantBtnSave="primary"
        size="sm"
        body={
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="d-flex justify-content-center align-items-center car-logo mb-n1">
              <IcoMoon size="45" icon="car" />
            </div>
            <p className="mt-4 font-weight-bold" style={{ fontSize: '16px' }}>
              Debes agregar un auto para ofertar
            </p>
          </div>
        }
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </>
  );
};

const mapStateToProps = state => {
  const { user } = state.auth;
  return {
    user
  };
};

export default connect(mapStateToProps)(DriverDashboard);
