import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { FormikInput, FormikNumberFormat } from '../Utils/Input';
import { UploadImage } from '../Utils/Upload';
import ImageCropper from '../Utils/Upload/ImageCropper';

const BasicProfileFields = ({
  modelName,
  errors,
  touched,
  user,
  setFieldValue
}) => {
  return (
    <>
      <Row>
        <Col md={4}>
          <Field name="user[avatar]">
            {({ field }) => {
              return (
                <ImageCropper
                  {...field}
                  accept="image/png, image/jpg, image/jpeg"
                  numberId="01"
                  setFieldValue={setFieldValue}
                  imageUrl={getIn(user.avatarProfile, 'fileUrlMinified')}
                  onChange={images => setFieldValue(field.name, images)}
                />
              );
            }}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field name={`${modelName}[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                inputType="text"
                label="Nombre"
                placeholder="Ingresa tu nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[lastName]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                inputType="text"
                label="Apellido"
                placeholder="Ingresa tu apellido"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="user[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="text"
                label="Correo"
                disabled
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[phone]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                phoneFormat
                label="Teléfono"
                placeholder="Ingresa tu teléfono"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={8}>
          <Field name={`${modelName}[description]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                as="textarea"
                className="text-area-profile"
                inputType="text"
                label="Descripción"
                placeholder="Ingresa una descripción"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default BasicProfileFields;
